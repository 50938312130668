import "core-js/modules/es.array.concat.js";
import http from '../../libs/http';
export var loadPermissions = function loadPermissions() {
  return http.get('/api/permission-user-all');
};
var basicRoute = '/api/permissions';
export var getPermissionList = function getPermissionList(params) {
  return http.get(basicRoute, {
    params: params
  });
};
export var addPermission = function addPermission(data) {
  return http.post(basicRoute, data);
};
export var editPermission = function editPermission(id, data) {
  return http.put("".concat(basicRoute, "/").concat(id), data);
};
export var deletePermission = function deletePermission(id) {
  return http.delete("".concat(basicRoute, "/").concat(id));
};