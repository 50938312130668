import localforage from 'localforage';
import Cookies from 'js-cookie';
var TokenKey = 'Admin-Token';
var PERMISSION = 'permission';
var USERINFO = 'userinfo';
export function getToken() {
  return Cookies.get(TokenKey);
}
export function setToken(token) {
  return Cookies.set(TokenKey, token);
}
export function removeToken() {
  return Cookies.remove(TokenKey);
}
export var setUserInfo = function setUserInfo(userInfo) {
  return localforage.setItem(USERINFO, userInfo);
};
export var getUserinfo = function getUserinfo() {
  return localforage.getItem(USERINFO);
};
export var removeUserinfo = function removeUserinfo() {
  return localforage.removeItem(USERINFO);
};
export var setPermissions = function setPermissions(permissions) {
  return localforage.setItem(PERMISSION, permissions);
};
export var getPermissions = function getPermissions() {
  return localforage.getItem(PERMISSION);
};
export var removePermissions = function removePermissions() {
  return localforage.removeItem(PERMISSION);
};