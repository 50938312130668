module.exports = {
  sidebarLogo: true,
  logo: 'https://resource.hjlh168.com/logo-notxt.png',
  admin: {
    appName: {
      fullName: '皇家荣耀会员管理',
      abbrName: '皇家荣耀'
    },
    locale: 'zh'
  },
  locale: 'zh',
  title: '皇家荣耀会员管理平台'
};