"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var getters = {
  sidebar: function sidebar(state) {
    return state.app.sidebar;
  },
  logo: function logo(state) {
    return state.app.logo;
  },
  title: function title(state) {
    return state.app.title;
  },
  device: function device(state) {
    return state.app.device;
  },
  language: function language(state) {
    return state.app.language;
  },
  visitedViews: function visitedViews(state) {
    return state.tagsView.visitedViews;
  },
  cachedViews: function cachedViews(state) {
    return state.tagsView.cachedViews;
  },
  permissions: function permissions(state) {
    return state.permission.permissions;
  },
  token: function token(state) {
    return state.user.token;
  },
  userInfo: function userInfo(state) {
    return state.user.userInfo;
  },
  menus: function menus(state) {
    return state.menu.menus;
  },
  roles: function roles(state) {
    return state.user.roles;
  }
};
var _default = exports.default = getters;