import "core-js/modules/es.array.concat.js";
import defaultSettings from '@/settings';
import VueI18n from '@/lang';
var title = defaultSettings.title || 'Agent Admin';
export default function getPageTitle(pageTitle) {
  if (pageTitle) {
    pageTitle = VueI18n.tc("".concat(pageTitle));
    return "".concat(pageTitle, " - ").concat(title);
  }
  return "".concat(title);
}