import "D:\\nodeApp\\hj-aadmin-vue-new\\node_modules\\core-js\\modules\\es.array.iterator.js";
import "D:\\nodeApp\\hj-aadmin-vue-new\\node_modules\\core-js\\modules\\es.promise.js";
import "D:\\nodeApp\\hj-aadmin-vue-new\\node_modules\\core-js\\modules\\es.object.assign.js";
import "D:\\nodeApp\\hj-aadmin-vue-new\\node_modules\\core-js\\modules\\es.promise.finally.js";
import Vue from 'vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './styles/element-variables.scss';
import '@/styles/index.scss'; // global css
import App from './App.vue';
import router from './router';
import store from './store';
import _i18n from './lang';
import './icons'; // icon
import './permission';
import permission from './directive/permission';
import VueHtmlToPaper from 'vue-html-to-paper';
import VueClipboard from 'vue-clipboard2';
var options = {
  name: '_blank',
  specs: ['fullscreen=yes', 'titlebar=yes', 'scrollbars=yes'],
  styles: ['https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css', './print.css']
};
Vue.use(VueHtmlToPaper, options);
Vue.use(VueClipboard);
Vue.use(ElementUI, {
  size: 'small',
  // set element-ui default size default is medium
  i18n: function i18n(key, value) {
    return _i18n.t(key, value);
  }
});
Vue.use(permission.install);

// 暂时不删除 provider, 因为用到的地方很多,改起来太麻烦,还有一点就是方便后期把后台合并
// Vue.prototype.$provider = "admin";

// i18n.locale = config.locale ? config.locale : "en";

// eslint-disable-next-line no-unused-vars
var app = new Vue({
  el: '#app',
  router: router,
  store: store,
  i18n: _i18n,
  render: function render(h) {
    return h(App);
  }
});