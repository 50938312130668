import Vue from 'vue';
import Vuex from 'vuex';
import app from './modules/app';
import menu from './modules/menu';
import permission from './modules/permission';
import user from './modules/user';
import tagsView from './modules/tagsView';
import getters from './getters';
Vue.use(Vuex);
var store = new Vuex.Store({
  modules: {
    app: app,
    menu: menu,
    permission: permission,
    user: user,
    tagsView: tagsView
  },
  getters: getters
  // plugins: [plugin]
});
export default store;