var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "navbar"
  }, [_c("hamburger", {
    staticClass: "hamburger-container",
    attrs: {
      id: "hamburger-container",
      "is-active": _vm.sidebar.opened
    },
    on: {
      toggleClick: _vm.toggleSideBar
    }
  }), _c("breadcrumb", {
    staticClass: "breadcrumb-container",
    attrs: {
      id: "breadcrumb-container"
    }
  }), _c("div", {
    staticClass: "right-menu"
  }, [_vm.device !== "mobile" ? [_c("lang-select", {
    staticClass: "right-menu-item hover-effect"
  })] : _vm._e(), _c("el-dropdown", {
    staticClass: "avatar-container right-menu-item hover-effect"
  }, [_c("div", {
    staticClass: "avatar-wrapper"
  }, [_c("span", [_vm._v(_vm._s(_vm.userInfo.sn))]), _c("i", {
    staticClass: "el-icon-caret-bottom"
  })]), _c("el-dropdown-menu", {
    attrs: {
      slot: "dropdown"
    },
    slot: "dropdown"
  }, [_c("el-dropdown-item", {
    attrs: {
      divided: ""
    },
    nativeOn: {
      click: function click($event) {
        return _vm.logout.apply(null, arguments);
      }
    }
  }, [_c("span", {
    staticStyle: {
      display: "block"
    }
  }, [_vm._v(_vm._s(_vm.$t("common.logout")))])])], 1)], 1)], 2)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };