"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = {
  computed: {
    language: function language() {
      return this.$store.getters.language;
    }
  },
  methods: {
    handleSetLanguage: function handleSetLanguage(lang) {
      this.$i18n.locale = lang;
      this.$store.dispatch('app/setLanguage', lang);
      this.$message({
        message: 'Switch Language Success',
        type: 'success'
      });
    }
  }
};