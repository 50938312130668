var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "app-wrapper",
    class: _vm.classObj
  }, [_vm.device === "mobile" && _vm.sidebar.opened ? _c("div", {
    staticClass: "drawer-bg",
    on: {
      click: _vm.handleClickOutside
    }
  }) : _vm._e(), _c("sidebar", {
    staticClass: "sidebar-container"
  }), _c("div", {
    staticClass: "main-container needTagsView"
  }, [_c("div", [_c("navbar"), _vm.needTagsView ? _c("tags-view") : _vm._e()], 1), _c("app-main")], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };