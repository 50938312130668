import _objectSpread from "D:/nodeApp/hj-aadmin-vue-new/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { AppMain, Navbar, Sidebar, TagsView } from '@/layout/components';
import resizeHandler from '@/layout/components/mixin/ResizeHandler';
import { mapState } from 'vuex';
export default {
  components: {
    AppMain: AppMain,
    TagsView: TagsView,
    Sidebar: Sidebar,
    Navbar: Navbar
  },
  mixins: [resizeHandler],
  data: function data() {
    return {
      needTagsView: true
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState({
    sidebar: function sidebar(state) {
      return state.app.sidebar;
    },
    device: function device(state) {
      return state.app.device;
    }
  })), {}, {
    classObj: function classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === 'mobile'
      };
    }
  }),
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    handleClickOutside: function handleClickOutside() {
      this.$store.dispatch('app/closeSideBar', {
        withoutAnimation: false
      });
    }
  }
};