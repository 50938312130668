"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.map.js");
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.object.to-string.js");
var _login = require("../../api/sys/login");
var _auth = require("../../libs/auth");
var state = {
  token: (0, _auth.getToken)(),
  userInfo: null,
  roles: []
};
var getters = {
  token: function token(state) {
    return state.token;
  },
  userInfo: function userInfo(state) {
    return state.userInfo;
  }
};
var mutations = {
  SET_TOKEN: function SET_TOKEN(state, token) {
    state.token = token;
  },
  SET_USERINFO: function SET_USERINFO(state, userInfo) {
    state.userInfo = userInfo;
  },
  SET_ROLES: function SET_ROLES(state, roles) {
    state.roles = roles;
  },
  UPDATE_2FA: function UPDATE_2FA(state, data) {
    state.userInfo.isOpen2fa = data.isOpen2fa;
  }
};
var actions = {
  loginHandle: function loginHandle(_ref, _ref2) {
    var commit = _ref.commit;
    var username = _ref2.username,
      password = _ref2.password,
      captcha = _ref2.captcha,
      ckey = _ref2.ckey,
      real_ip = _ref2.real_ip;
    return new Promise(function (resolve, reject) {
      return (0, _login.login)({
        username: username,
        password: password,
        captcha: captcha,
        ckey: ckey,
        real_ip: real_ip
      }).then(function (res) {
        var data = res.data;
        if (!data.isOpen2fa) {
          var token = data.access_token;
          delete data.access_token;
          commit('SET_TOKEN', token);
          (0, _auth.setToken)(token);
        }
        resolve(data);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  setUserInfo: function setUserInfo(_ref3) {
    var commit = _ref3.commit;
    return new Promise(function (resolve, reject) {
      return (0, _login.getLoginUser)().then(function (response) {
        var userInfo = response.data;
        commit('SET_USERINFO', userInfo);
        if (userInfo.roles && userInfo.roles instanceof Array) {
          var roles = userInfo.roles.map(function (role) {
            return role.name;
          });
          commit('SET_ROLES', roles);
        }
        (0, _auth.setUserInfo)(userInfo);
        resolve(userInfo);
      }).catch(function () {
        // reject(error);
      });
    });
  },
  // eslint-disable-next-line no-unused-vars
  resetToken: function resetToken(_ref4) {
    var commit = _ref4.commit;
    (0, _auth.removeToken)();
    (0, _auth.removePermissions)();
    (0, _auth.removeUserinfo)();
  },
  updateUserInfo: function updateUserInfo(_ref5, data) {
    var commit = _ref5.commit;
    commit('UPDATE_2FA', data);
  }
};
var _default = exports.default = {
  namespaced: true,
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
};