"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dropdown", {
    staticClass: "international",
    attrs: {
      trigger: "click"
    },
    on: {
      command: _vm.handleSetLanguage
    }
  }, [_c("div", [_c("svg-icon", {
    attrs: {
      "class-name": "international-icon",
      "icon-class": "international"
    }
  })], 1), _c("el-dropdown-menu", {
    attrs: {
      slot: "dropdown"
    },
    slot: "dropdown"
  }, [_c("el-dropdown-item", {
    attrs: {
      disabled: _vm.language === "zh-CN",
      command: "zh-CN"
    }
  }, [_vm._v(" 中文 ")]), _c("el-dropdown-item", {
    attrs: {
      disabled: _vm.language === "en",
      command: "en"
    }
  }, [_vm._v(" English ")])], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;