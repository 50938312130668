"use strict";

var _interopRequireDefault = require("D:/nodeApp/hj-aadmin-vue-new/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.verify2Fa = exports.logout = exports.login = exports.getLoginUser = exports.getCaptcha = exports.generateTwoFactor = exports.enableTwoFactor = exports.disableTwoFactor = void 0;
var _http = _interopRequireDefault(require("../../libs/http"));
var login = exports.login = function login(_ref) {
  var username = _ref.username,
    password = _ref.password,
    captcha = _ref.captcha,
    ckey = _ref.ckey,
    real_ip = _ref.real_ip;
  return _http.default.post('/api/login', {
    sn: username,
    password: password,
    captcha: captcha,
    real_ip: real_ip,
    ckey: ckey
  });
};
var verify2Fa = exports.verify2Fa = function verify2Fa(data) {
  return _http.default.post('/api/verify2Fa', data);
};
var getCaptcha = exports.getCaptcha = function getCaptcha() {
  return _http.default.get('/api/captcha');
};
var getLoginUser = exports.getLoginUser = function getLoginUser() {
  return _http.default.get('/api/login-user');
};
var generateTwoFactor = exports.generateTwoFactor = function generateTwoFactor(params) {
  return _http.default.get("agent-api/login-user/generateTwoFactor", params);
};
var enableTwoFactor = exports.enableTwoFactor = function enableTwoFactor(params) {
  return _http.default.post("agent-api/login-user/enableTwoFactor", params);
};
var disableTwoFactor = exports.disableTwoFactor = function disableTwoFactor(params) {
  return _http.default.post("agent-api/login-user/disableTwoFactor", params);
};
var logout = exports.logout = function logout() {};