import Vue from 'vue';
import Router from 'vue-router';
import routes from './routers';
Vue.use(Router);

// const router = new Router({
//   mode: 'hash',
//   saveScrollPosition: true,
//   routes
// })

var createRouter = function createRouter() {
  return new Router({
    // mode: 'history', // require service support
    saveScrollPosition: true,
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    // constantRoutes // 这样写竟然不报错,并且一片空白,奇葩
    routes: routes
  });
};
var router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}
export default router;