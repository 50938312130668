import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import Layout from '@/layout';
import { myMenu } from '@/api/sys/menu';
// import { setMenus } from '@/libs/auth'

// https://juejin.cn/post/6844904082050646023
var loadView = function loadView(viewPath) {
  // 用字符串模板实现动态 import 从而实现路由懒加载,不推荐,并且VUE_CLI_BABEL_TRANSPILE_MODULES为true时失效
  // https://github.com/PanJiaChen/vue-element-admin/issues/2075
  // return () => import(`@/views/${viewPath}.vue`)
  return function (resolve) {
    return require(["@/views/".concat(viewPath, ".vue")], resolve);
  };
};
var filterAsyncRouter = function filterAsyncRouter(routeList) {
  // 权限已经后台过滤了
  return routeList.filter(function (route) {
    if (route.component === 'layout') {
      // 如果 layout = layout 说明是布局组件,将真正的布局组件赋值给它
      route.component = Layout;
    } else {
      // 如果不是布局组件就只能是页面的引用了
      // 利用懒加载函数将实际页面赋值给它
      route.component = loadView(route.component);
    }
    var meta = {};
    if (route.icon) {
      meta.icon = route.icon;
      delete route.icon;
    }
    if (route.title) {
      meta.title = route.title;
      delete route.title;
    }
    if (route.no_cache) {
      meta.noCache = !route.no_cache;
      delete route.no_cache;
    }
    if (route.breadcrumb) {
      meta.breadcrumb = !!route.breadcrumb;
      delete route.breadcrumb;
    }
    if (route.affix) {
      meta.affix = !!route.affix;
      delete route.affix;
    }
    route.meta = meta;
    // 判断是否存在子路由，并递归调用自己
    if (route.children && route.children.length) {
      route.children = filterAsyncRouter(route.children);
    }
    return true;
  });
};
var state = {
  menus: [],
  hasGetRoute: true
};
var mutations = {
  SET_MENUS: function SET_MENUS(state, menus) {
    // setMenus(menus)
    // 再用路由解析函数解析 List 为真正的路由列表
    state.menus = filterAsyncRouter(menus);
    // 修改路由获取状态
    state.hasGetRoute = true;
  }
};
var actions = {
  loadMenus: function loadMenus(_ref) {
    var commit = _ref.commit;
    return new Promise(function (resolve, reject) {
      myMenu().then(function (data) {
        var menu = data.data;
        commit('SET_MENUS', menu);
        resolve(menu);
      }).catch(function (error) {
        reject(error);
      });
    });
  }
};
export default {
  state: state,
  mutations: mutations,
  actions: actions
};