import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import Layout from '@/layout';

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles) 这个暂时没有用到,权限通过后台接口直接过滤了
    permission: 'user.list'      至少拥有的权限,用户只有拥有这个权限才能访问 这个暂时没有用到,权限通过后台接口直接过滤了
    title: 'title'               the name show in sidebar and breadcrumb (recommend set) (支持多语言)
    icon: 'svg-name'             the icon show in the sidebar
    noCache: true                if set true, the page will no be cached(default is false)
    affix: true                  if set true, the tag will affix in the tags-view
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

export default [{
  name: 'adminLogin',
  path: '/admin/login',
  component: function component() {
    return import('@/views/admin/login/index.vue');
  }
}, {
  path: '/',
  name: 'main',
  component: Layout,
  redirect: '/dashboard'
  // children: [
  //   {
  //     name: 'Dashboard',
  //     path: 'dashboard',
  //     component: () => import('@/views/admin/dashboard/index'),
  //     meta: { title: 'menu.dashboard', icon: 'dashboard', affix: true }
  //   }
  // ]
}];
export var errorRoute = {
  path: '*',
  name: 'error_404',
  meta: {},
  component: function component() {
    return import('@/views/404.vue');
  }
};