import "core-js/modules/es.object.to-string.js";
import { loadPermissions as _loadPermissions } from '../../api/sys/permission';
import { setPermissions } from '@/libs/auth';
var state = {
  permissions: []
};
var getters = {
  permissions: function permissions(state) {
    return state.permissions;
  }
};
var mutations = {
  SET_PERMISSIONS: function SET_PERMISSIONS(state, permissions) {
    setPermissions(permissions);
    state.permissions = permissions;
  }
};
var actions = {
  loadPermissions: function loadPermissions(_ref) {
    var commit = _ref.commit;
    return new Promise(function (resolve, reject) {
      _loadPermissions().then(function (data) {
        // TODO: 判断数据正确性
        var permissions = data.data;
        commit('SET_PERMISSIONS', permissions);
        resolve(permissions);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  resetPermissions: function resetPermissions(_ref2) {
    var commit = _ref2.commit;
    commit('SET_PERMISSIONS', []);
  }
};
export default {
  namespaced: true,
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
};