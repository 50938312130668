import _objectSpread from "D:/nodeApp/hj-aadmin-vue-new/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { mapGetters } from 'vuex';
export default {
  name: 'SidebarLogo',
  props: {
    collapse: {
      type: Boolean,
      required: true
    }
  },
  data: function data() {
    return {};
  },
  computed: _objectSpread({}, mapGetters(['title', 'logo']))
};