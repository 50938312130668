"use strict";

var _interopRequireDefault = require("D:/nodeApp/hj-aadmin-vue-new/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.object.to-string.js");
var _axios = _interopRequireDefault(require("axios"));
var _elementUi = require("element-ui");
var _store = _interopRequireDefault(require("@/store"));
var _auth = require("@/libs/auth");
// 原始版本加了这一句之后会全部空白,原因是App.vue import的utils有引入了router
// import router from "../router";

var httpRequest = _axios.default.create({
  timeout: 300000,
  baseURL: process.env.VUE_APP_BASE_API
});
httpRequest.interceptors.request.use(function (config) {
  // do something before request is sent

  // if (store.getters.token) {
  // let each request carry token
  // ['X-Token'] is a custom headers key
  // please modify it according to the actual situation
  var token = (0, _auth.getToken)();
  config.headers.common.Authorization = "Bearer ".concat(token);
  // }
  return config;
}, function (error) {
  // do something with request error
  return Promise.reject(error);
});
httpRequest.interceptors.response.use(function (response) {
  // 成功直接返回data,若服务端没有返回,data是空字符串

  return response.data;
}, function (error) {
  // let message = error.response.data.message
  //   ? error.response.data.message
  //   : error.response.statusText;
  // Unauthorized
  if (error.response.status === 401) {
    // to re-login
    _elementUi.MessageBox.alert('Your account has been logged in on other devices and you are offline. Please log in again if necessary.', 'Confirm to log in again', {
      confirmButtonText: 'Return to login interface',
      type: 'warning'
    }).then(function () {
      _store.default.dispatch('user/resetToken').then(function () {
        location.reload();
      });
    });
  }
  // let dangerouslyUseHTMLString = false;
  // if (
  //   error.response.status === 422 &&
  //   error.response.data.hasOwnProperty("errors")
  // ) {
  //   message += "<br>";
  //   for (let key in error.response.data.errors) {
  //     let items = error.response.data.errors[key];
  //     if (typeof items === "string") {
  //       message += `${items} <br>`;
  //     } else {
  //       error.response.data.errors[key].forEach(item => {
  //         message += `${item} <br>`;
  //       });
  //     }
  //   }
  //   dangerouslyUseHTMLString = true;
  // }

  // Message({
  //   dangerouslyUseHTMLString,
  //   message: message,
  //   type: "error",
  //   duration: 5 * 1000
  // });

  return Promise.reject(error.response.data);
});
var _default = exports.default = httpRequest;