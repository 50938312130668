"use strict";

var _interopRequireDefault = require("D:/nodeApp/hj-aadmin-vue-new/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.error.cause.js");
require("core-js/modules/es.array.includes.js");
require("core-js/modules/es.string.includes.js");
var _store = _interopRequireDefault(require("@/store"));
var _default = exports.default = {
  inserted: function inserted(el, binding, vnode) {
    var value = binding.value;
    var roles = _store.default.getters && _store.default.getters.roles;
    if (roles.includes('agent-super')) return;
    var permissions = _store.default.getters && _store.default.getters.permissions;
    if (permissions && permissions instanceof Array) {
      // 暂时只支持一个string,如果一个按钮需要多个权限,那就传array
      var hasPermission = permissions.includes(value);
      if (!hasPermission) {
        el.parentNode && el.parentNode.removeChild(el);
      }
    } else {
      throw new Error("user must need permissions! Like \"['admin','editor']\"");
    }
  }
};