import "core-js/modules/es.array.concat.js";
import qs from 'qs';
import http from '../../libs/http';
var basicRoute = '/api/menus';
export var myMenu = function myMenu() {
  return http.get('/api/my-menu');
};
export var getMenuList = function getMenuList(params) {
  return http.get(basicRoute, {
    params: params
  });
};
export var addMenu = function addMenu(data) {
  return http.post(basicRoute, data);
};
export var editMenu = function editMenu(id, data) {
  return http.put("".concat(basicRoute, "/").concat(id), qs.stringify(data));
};
export var deleteMenu = function deleteMenu(id) {
  return http.delete("".concat(basicRoute, "/").concat(id));
};